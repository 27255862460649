import moment from 'moment';

export default [
  {
    name: 'branchLocation',
    component: 'select',
    labelAbove: 'Please select the branch location'
  },
  {
    name: 'meetingDate',
    component: 'datepicker',
    type: 'date',
    min: moment().format('MM-DD-YYYY'),
    labelAbove: 'What day would you like to meet?'
  }
];
