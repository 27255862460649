import React, { useContext } from 'react';
import { Context } from 'store';

import { RoutesConfig } from '_src/modules/Router';
import ModalHeading from '_src/components/ModalHeading';
import ModalBody from '_src/components/ModalBody';
import { Button } from '_src/components/ModalButton';
import { phone, office } from '_src/assets/images';
import { PageContainer } from './styles';

const options = {
  'In-Person Only': [{ uri: office, label: 'In-Person', value: 'Meeting' }],
  'Phone Only': [{ uri: phone, label: 'Phone Call', value: 'Call' }],
  'In-Person or Phone acceptable': [
    { uri: phone, label: 'Phone Call', value: 'Call' },
    { uri: office, label: 'In-Person', value: 'Meeting' }
  ],
  'No Sits': []
};

const ScheduleVisit = () => {
  const { getData, setData } = useContext(Context);
  const { navigateTo, contact } = getData();

  const { meetingSchedule } = contact;

  const renderButtons = () => {
    return (
      <PageContainer>
        {options[meetingSchedule].map((option, i) => {
          return (
            <Button
              key={i}
              onClick={() => {
                setData('contact', { ...contact, meetingSchedule: option.value });
                navigateTo(RoutesConfig.ScheduleVisitForm);
              }}
              size="big"
              imageProps={{
                uri: option.uri,
                label: option.label
              }}
            />
          );
        })}
      </PageContainer>
    );
  };

  return (
    <>
      <ModalHeading title="Schedule a Visit" subtitle="How would you like to meet?" />

      <ModalBody>{renderButtons()}</ModalBody>
    </>
  );
};

export default ScheduleVisit;
