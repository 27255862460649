import React, { useContext } from 'react';
import { Context } from 'store';

import ModalHeading from '_src/components/ModalHeading';
import ModalBody from '_src/components/ModalBody';
import { PageContainer, TextHelper, Image, DetailsContainer } from './styles';
import { envelope } from '_src/assets/images';

const AppointmentDetails = () => {
  const { getData } = useContext(Context);

  const { contact } = getData();

  const successText =
    contact?.meetingSchedule !== 'Call'
      ? `Please remember to bring your Drivers
              License with you, in your appointment.`
      : '';

  const subtitleText =
    contact?.meetingSchedule !== 'Call'
      ? 'Thanks for scheduling! Looking forward to meeting you!'
      : 'Thanks for scheduling! One of our enrollment specialist will call you soon about starting your new career in trucking!';

  return (
    <>
      <ModalHeading title="Thank you!" subtitle={subtitleText} />

      <ModalBody>
        <PageContainer>
          <Image src={envelope} alt="evnelope" />

          <DetailsContainer>
            <TextHelper>You will receive a confirmation email shortly. {successText}</TextHelper>
          </DetailsContainer>
        </PageContainer>
      </ModalBody>
    </>
  );
};

export default AppointmentDetails;
