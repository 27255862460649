import { styled } from 'styled-components';

export const PageContainer = styled.div``;

export const AvailableTimesContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto auto auto;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
  padding-bottom: 20px;
`;

export const Label = styled.div`
  text-align: center;
  margin: 20px 0;
  font-size: 18px;
`;

export const Date = styled.div`
  font-size: 20px;
  color: orange;
  font-weight: bold;
`;

export const SlotsContainer = styled.div`
  height: 300px;
  padding: 10px 10px;
  overflow-y: scroll;
  box-shadow: ${props => (props.isEmpty ? '' : '0px 4px 8px 0px rgba(0, 0, 0, 0.3)')};

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${props => (props.isEmpty ? 'trasnparent' : '#eee')};
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f57b20;
    border-radius: 35px;
  }
`;

export const EmptyState = styled.div`
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
`;

export const Warning = styled.div`
  text-align: ${props => (props.color ? 'start' : 'center')};
  font-size: 19px;
  margin: ${props => (props.color ? '10px 0' : '15px 0')};
  color: ${props => props.color ?? '#f57b20'};
  font-weight: ${props => props.fontWeight ?? 'bold'};
`;
