import React, { useContext, useRef, useEffect, useState } from 'react';
import { Context, useFetch, useSave } from 'store';

import { Form } from 'form';
import FormHelper from '_src/components/FormHelper';
import config from '_src/services/scheduleVisit';
import {
  getLocationsConfig,
  createEventConfig,
  updateContactConfig
} from '_src/services/apiConfig';

import { RoutesConfig } from '_src/modules/Router';
import ModalHeading from '_src/components/ModalHeading';
import ModalBody from '_src/components/ModalBody';
import { zipDetails } from '_src/utils/zipHelpers';

import { Button, OutlinedButton } from '_src/components/ModalButton';
import {
  PageContainer,
  AvailableTimesContainer,
  Label,
  Date,
  SlotsContainer,
  EmptyState,
  Warning
} from './styles';
import { generateFreeTimeSlots } from './freeSlots';
import moment from 'moment';

const meetingOptions = {
  'In-Person Only': 'Meeting',
  'Phone Only': 'Call'
};

const ScheduleVisit = () => {
  const formApi = useRef(null);
  const { getData, setData } = useContext(Context);
  const { navigateTo, contact } = getData();

  const { get: getLocations } = useFetch(getLocationsConfig);
  const { save: createNewEvent } = useSave(createEventConfig);
  const { save: updateContact } = useSave(updateContactConfig);

  const [availableHours, setAvailableHours] = useState([]);
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { meetingSchedule, id, accountId, lat, lng, zip, accountsZip } = contact || {};

  useEffect(() => {
    const getData = async () => {
      const zipCodeDetails = zipDetails(accountsZip);
      const { longitude, latitude } = zipCodeDetails;

      await getLocations({
        params: { accounts_zip: accountsZip, contact_lat: latitude, contact_lng: longitude }
      }).then(response => {
        let filteredLocations = response[0].data.accounts.filter(({ appointmentType }) => {
          if (appointmentType === 'In-Person or Phone acceptable') {
            return true;
          }

          if (meetingSchedule === 'Call') {
            return appointmentType === 'Phone Only';
          }

          return appointmentType === 'In-Person Only';
        });

        const preSelectLocation = filteredLocations.find(({ id }) => id == accountId);

        const formatedLocations = filteredLocations.map(item => {
          const { zip, street, city, state, id } = item;

          return {
            label: `${zip} ${street} ${city} ${state}`,
            value: `${id}`,
            ...item
          };
        });
        formApi.current.setField('branchLocation', `${preSelectLocation?.id}`);
        setLocations(formatedLocations);
      });
    };

    getData();
  }, []);

  const createEvent = appointmentDate => {
    const formIsValid = formApi.current.validate();

    if (formIsValid) {
      const { branchLocation } = formApi.current.values();
      const branch = locations.find(({ id }) => id === Number(branchLocation));
      setError(null);
      setLoading(true);

      createNewEvent({
        contactId: id,
        appointmentType: meetingSchedule ?? 'No Sits',
        appointmentDate: moment(appointmentDate).toISOString(),
        localTime: moment.utc(appointmentDate).local().format('MM-DD-YYYY HH:mm')
      })
        .then(res => {
          if (res.code === 429) {
            setLoading(false);
            setError('This slot is already booked. Please chosse another slot.');
            return;
          }

          if (res.code !== 201) {
            setLoading(false);
            setError('Something went wrong!');
            return;
          }

          setData('event', { ...res.data.event, location: branch });
          navigateTo(RoutesConfig.AppointmentDetails);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setError('Something went wrong!');
        });
    }
  };

  const handleDateChange = async date => {
    const isAddresValid = formApi.current.validate();

    if (isAddresValid) {
      const { branchLocation } = formApi.current.values();
      const selectedBranch = locations.find(({ id }) => id === Number(branchLocation));

      const duration = selectedBranch.appointmentDuration.split(' ')[0];

      const activityEnd = moment(date).add(5, 'days');

      const params = new URLSearchParams({
        requestType: 'getEvents',
        OwnerId: selectedBranch.ownerId,
        ActivityDate: moment(date).format('YYYY-MM-DD'),
        EndDate: moment(activityEnd).format('YYYY-MM-DD')
      });

      fetch(`https://qa-api-widget.160digital.com/event/get_salesforce_events?${params.toString()}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(response => {
          const filterBusyEvents = response.data.events.filter(({ ActivityDate }) =>
            moment(ActivityDate).isSameOrAfter(date)
          );

          const groupedEvents = filterBusyEvents.reduce((acc, event) => {
            const date = event.ActivityDate;
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(event);
            return acc;
          }, {});

          const freeSlots = generateFreeTimeSlots(
            Number(duration),
            groupedEvents,
            {
              workingHours: {
                start: moment.utc(selectedBranch.openingHours),
                close: moment.utc(selectedBranch.closingHours)
              }
            },
            date
          );

          setAvailableHours(freeSlots);
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
    }
  };

  const handleLocationChange = async () => {
    const { meetingDate, branchLocation } = formApi?.current?.values() || {};

    const branch = locations.find(({ id }) => id === Number(branchLocation));

    await updateContact({
      id: contact.id,
      accountId: branch.id,
      ownerId: branch.ownerId,
      meetingSchedule: branch.appointmentType
    })
      .then(response => {
        if (response.code !== 204) {
          setError('Something went wrong!');
          return;
        }
        setLoading(false);
        setData('contact', {
          ...contact,
          ...response.data.contact,
          meetingSchedule: meetingOptions[response.data.contact.appointmentType]
        });
      })
      .catch(() => {
        setLoading(false);
        setError('Updating contact failed !');
      });

    if (meetingDate) {
      handleDateChange(meetingDate);
    }
  };

  const renderEmptyState = () => {
    const { meetingDate } = formApi?.current?.values() || {};

    if (!meetingDate) {
      return <EmptyState>Please select location and date!</EmptyState>;
    } else {
      availableHours.length === 0 && (
        <EmptyState>All slots are busy, please try another date!</EmptyState>
      );
    }
    return (
      <>
        <Warning fontWeight="normal">
          Below are some options around the date that you selected. Click below to pick a time slot!
        </Warning>
        <Warning>Please note that the time-slots shown here are in your local timezone!</Warning>
      </>
    );
  };

  const renderFreeSlots = () => {
    return (
      <>
        {Object.keys(availableHours).map(key => {
          return (
            <div key={key}>
              <Date>Date: {moment(key).format('MM-DD-YYYY')}</Date>

              <AvailableTimesContainer>
                {availableHours[key].map(({ start, end }, i) => {
                  const localTimeStart = moment.utc(start).local();
                  const localTimeEnd = moment.utc(end).local();
                  return (
                    <Button
                      key={i}
                      onClick={() => createEvent(start)}
                      size="small"
                      label={`${localTimeStart.format('hh:mm A')} - ${localTimeEnd.format(
                        'hh:mm A'
                      )}`}
                      width={'100%'}
                      disabled={loading}
                    />
                  );
                })}
              </AvailableTimesContainer>
            </div>
          );
        })}
      </>
    );
  };

  const formFields = [
    {
      ...config.form.fields[0],
      items: locations,
      onChange: handleLocationChange
    },
    {
      ...config.form.fields[1],
      onChange: handleDateChange,
      min: new window.Date().toISOString().split('T')[0]
    }
  ];

  return (
    <>
      <ModalHeading title="Schedule a Visit" />

      {Object.keys(availableHours).length > 0 && (
        <Warning color="#c17144">
          Appointment type: {meetingSchedule === 'Call' ? 'Call' : 'In person'}
        </Warning>
      )}
      <ModalBody error={error}>
        <PageContainer>
          <Form formApi={form => (formApi.current = form)} schema={config.form.schema}>
            <FormHelper fields={formFields} />
          </Form>
          {renderEmptyState()}
          <SlotsContainer isEmpty={Object.keys(availableHours).length === 0}>
            {renderFreeSlots()}
          </SlotsContainer>

          {Object.keys(availableHours).length > 0 && (
            <>
              <Label>
                If none of the above times does not fit for you, you can try to choose another date
              </Label>

              <OutlinedButton
                label="I do not see a time here that works for me"
                width={'100%'}
                onClick={() => navigateTo(RoutesConfig.FinalStep)}
                loading={loading}
              />
            </>
          )}
        </PageContainer>
      </ModalBody>
    </>
  );
};

export default ScheduleVisit;
