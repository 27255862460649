import { styled } from 'styled-components';

const getColor = props => {
  return props.hasError ? '#FF7B5C' : '#707070';
};

const Picker = styled.input`
  font-size: 15px;
  box-sizing: border-box;
  user-select: none;
  height: 45px;
  line-height: 44px;
  border-radius: 10px;
  border: 1px solid ${props => getColor(props)};
  display: flex;
  align-items: center;
  outline: none;
  color: grey;
  padding: 5px;
  ::-webkit-calendar-picker-indicator {
    opacity: 0.5;
  }
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '20px')};
  width: ${props => (props.width ? props.width : '100%')};
`;

const Label = styled.div`
  color: ${({ hasError }) => (hasError ? '#FF7B5C' : '#F57B20')};
  font-size: 10px;
  position: absolute;
  left: 20px;
  top: ${({ hasValue }) => (hasValue ? 5 : 18)}px;
  opacity: ${({ hasValue }) => (hasValue ? 1 : 0)};
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
`;

const LabelAbove = styled.div`
  color: ${({ hasError }) => (hasError ? '#FF7B5C' : '#F57B20')};
  font-size: 16px;
  margin-bottom: 10px;
`;

const Error = styled.div`
  color: #f57b20;
  font-size: 10px;
  margin-top: -15px;
`;

export { Picker, Label, LabelAbove, Error };
