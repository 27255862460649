import React from 'react';
import { Picker, LabelAbove, Error } from './styles';

const Datepicker = ({
  labelAbove,
  type,
  width,
  onChange,
  marginBottom,
  name,
  error,
  hasError = false,
  ...rest
}) => {
  return (
    <div>
      <>
        {labelAbove && <LabelAbove>{labelAbove}</LabelAbove>}
        <Picker
          type={type}
          placeholder={type === 'month' ? 'Month-Year' : 'yyyy-mm-dd'}
          width={width}
          {...(marginBottom ? { marginBottom: marginBottom } : undefined)}
          onChange={event => {
            onChange(event.target.value);
          }}
          name={name}
          hasError={hasError === true ? true : false}
          {...rest}
        />
      </>

      {error && <Error>{error}</Error>}
    </div>
  );
};

export default Datepicker;
