export default [
  {
    name: 'issueState',
    component: 'select',
    labelAbove: 'Issue State',
    items: GLOBALS.states,
    type: 'number',
    label: 'Please select state'
  },
  {
    name: 'licenseIssuedDate',
    component: 'datepicker',
    type: 'date',
    labelAbove: 'Issue Date'
  },
  {
    name: 'licenseExpirationDate',
    component: 'datepicker',
    type: 'date',
    labelAbove: 'Expire Date'
  }
];
