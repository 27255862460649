import React, { useState, useContext } from 'react';
import { Context, useSave } from 'store';

import { RoutesConfig } from '_src/modules/Router';
import { updateContactConfig } from '_src/services/apiConfig';
import ModalHeading from '_src/components/ModalHeading';
import ModalBody from '_src/components/ModalBody';
import { Button } from '_src/components/ModalButton';
import { schedule, phone } from '_src/assets/images';
import { ButtonContainer } from './styles';
import moment from 'moment';

const meetingOptions = {
  'In-Person Only': 'Meeting',
  'Phone Only': 'Call'
};

const Congratulations = () => {
  const { getData, setData } = useContext(Context);
  const { navigateTo, contact } = getData();
  const { save: updateContact } = useSave(updateContactConfig);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateUser = async route => {
    setError(null);
    setLoading(true);
    const {
      id,
      zipCode,
      issueDate,
      expireDate,
      city,
      longitude,
      latitude,
      state,
      appointmentType
    } = contact;

    const payload = {
      id: id,
      zip: zipCode,
      state: state,
      city: city,
      lat: latitude,
      lng: longitude,
      licenseIssuedDate: moment(issueDate).format('YYYY-MM-DD HH:mm'),
      licenseExpirationDate: moment(expireDate).format('YYYY-MM-DD HH:mm'),
      meetingSchedule: appointmentType,
      sent_to_sf: false,
      create_in_sf: false
    };

    await updateContact(payload)
      .then(response => {
        setLoading(false);
        if (response.code !== 204) {
          setError('Something went wrong!');
          return;
        }
        if (response.data.contact.appointmentType === 'No Sit') {
          return navigateTo(RoutesConfig.FinalStep);
        }

        if (response.data.contact.appointmentType !== 'In-Person or Phone acceptable') {
          setData('contact', {
            ...contact,
            meetingSchedule: meetingOptions[response.data.contact.appointmentType]
          });

          return navigateTo(RoutesConfig.ScheduleVisitForm);
        }

        setLoading(false);
        setData('contact', response.data.contact);
        navigateTo(route);
      })
      .catch(() => {
        setLoading(false);
        setError('Something went wrong!');
      });
  };

  return (
    <>
      <ModalHeading
        title="Congratulations!"
        subtitle={
          'You have been pre-approved to enroll. We would like to meet with you to discus your options for enrolling in the program. \n Please choose on of the options below to schedule your appointment'
        }
      />

      <ModalBody error={error}>
        <ButtonContainer>
          <Button
            disabled={loading}
            onClick={() => {
              updateUser(RoutesConfig.ScheduleVisit);
            }}
            size="big"
            imageProps={{
              uri: schedule,
              label: 'Schedule \n Now',
              description: 'Click here to pick a time and date to meet and discus enrollment.'
            }}
          />

          <Button
            disabled={loading}
            size="big"
            onClick={() => {
              navigateTo(RoutesConfig.FinalStep);
            }}
            imageProps={{
              uri: phone,
              label: 'Call Me',
              description:
                'One of our enrollment specialists will reach out soon about starting your new career.'
            }}
          />
        </ButtonContainer>
      </ModalBody>
    </>
  );
};

export default Congratulations;
